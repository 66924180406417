.header-button-block {
    .header {
        color: $color-dk-blue;
    }
    .button {
        margin-top: 4rem;
        margin-bottom: 4rem;
        height: 53px;
        width: 299px;
        background: none;
        border: solid $color-dk-blue 2px;
        border-radius: 6px;
        color: $color-dk-blue;
        background: linear-gradient(to right, $color-dk-blue 50%, $color-lt-grey-background 50%);
        background-size: 200% 100%;
        background-position: right bottom;
        transition: all .5s ease-out;
    }
    .button:hover {
        background-position: left bottom;
        color: white;
    }
}